// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/login.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/login.tsx");
}
// REMIX HMR END

import { Form, useActionData, useSearchParams } from "@remix-run/react";
import { badRequest } from "~/utils/request.server";
import styles from "~/styles/login.css";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { createAuthSession, login } from "~/utils/auth/session.server";
import i18n from "~/i18next.server";
import { capitalize, getFieldHTML } from "~/utils/misc";
import imgRight from "~/assets/general_images/img-connexion-optimbtp.web-light.png";
export function links() {
  return [{
    rel: "stylesheet",
    href: styles
  }];
}
function validateUsername(username, t) {
  if (username.length < 3) {
    return t("username_too_short");
  }
}
function validatePassword(password, t) {
  if (password.length < 6) {
    return t("password_too_short");
  }
}
export const action = async ({
  request
}) => {
  const form = await request.formData();
  const password = form.get("password");
  const username = form.get("username");
  const redirectTo = form.get("redirectTo") || "/";
  let t = await i18n.getFixedT(request);
  if (typeof password !== "string" || typeof username !== "string") {
    return badRequest({
      fieldErrors: null,
      fields: null,
      formError: t("incorrect_form")
    });
  }
  const fields = {
    password,
    username
  };
  const fieldErrors = {
    password: validatePassword(password, t),
    username: validateUsername(username, t)
  };
  if (Object.values(fieldErrors).some(Boolean)) {
    return badRequest({
      fieldErrors,
      fields,
      formError: null
    });
  }
  const data = await login({
    username,
    password
  });
  if (!data) {
    return badRequest({
      fieldErrors: null,
      fields,
      formError: t("incorrect_username_password")
    });
  }
  const {
    accessToken,
    refreshToken
  } = data;
  return createAuthSession(accessToken, refreshToken, redirectTo, "user", "/");
};
export default function Login() {
  _s();
  const actionData = useActionData();
  const [searchParams] = useSearchParams();
  const {
    t
  } = useTranslation();
  return <div className="d-flex h-100 login-container">
      <div className="container col-sm-5 form-container">
        <div className="login-form">
          <Form method="post" className="login-form-wrapper">
            <h2 className="welcome">{t("login")}</h2>
            <input type="hidden" name="redirectTo" value={searchParams.get("redirectTo") ?? undefined} />

            {getFieldHTML(t("user"), "username", actionData?.fieldErrors?.username, "text")}

            {getFieldHTML(t("password"), "password", actionData?.fieldErrors?.password, "password")}

            <div id="form-error-message">
              {actionData?.formError ? <p className="form-validation-error" role="alert">
                  {actionData.formError}
                </p> : null}
            </div>
            <div className="w-100 redirect-link">
              {t("forgot_password")} ? {capitalize(t("click"))}
              <span className="ml-1 orange-text text-bold no-pointer">
                {t("here")}
              </span>
            </div>
            <Button className="orange orange-hover login-btn border-none" type="submit" label={t("login")} />

          </Form>
          <div className="mt-4 mb-5 redirect-link text">
            {capitalize(t("no_account"))} ?
            <span className="ml-1 orange-text text-bold no-pointer truncate-text">
              {capitalize(t("page_login_se_renseigner"))}
            </span>
          </div>
        </div>
        <div className="texts-container">
          <div className="left-text">{t("page_login_droits_reserves")}</div>
          <div className="center-text">
            {t("page_login_conditions_generales")}
          </div>
          <div className="right-text">{t("page_login_politique")}</div>
        </div>
      </div>
      <div className="col-sm-7 h-100 banner-container">
        <img className="h-100" src={imgRight} alt="optim dashboard login" />
        {/* <div className="banner-title">
           {t("page_login_bienvenue")}
           <span className="highlight">{t("page_login_BTP")}</span>
           <span style={{ fontSize: "0.45em", marginTop: "0.6em" }}>.Web</span>
          </div>
          <div className="banner-img">
           <img className="img-banner" src={imgBanner} alt="" />
          </div> */}
      </div>
    </div>;
}
_s(Login, "YVrBgA6pZPJWLUdmVop2lgaCkO4=", false, function () {
  return [useActionData, useSearchParams, useTranslation];
});
_c = Login;
var _c;
$RefreshReg$(_c, "Login");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;